import React, {useEffect, useState} from "react";
import Modal from "../BasicModal";
import Container from "../../Container";
import "./BatchMachining.css"
import {
    onToggleSelectedMaterialGoodsIdOfDetailing,
    resetBatchMachiningFormAC
} from "../../../store/reducers/OrderReducer";
import {useDispatch} from "react-redux";
import {useLang} from "../../../context/langProvider";
import GroupOperations from "./GroupOperations";
import GroupParts from "./GroupParts";
import InputForm from "../../InputFields/InputForm/InputForm";
import EdgeOperations from "./EdgeOperations";


/**
 *
 * @param {boolean} open  -
 * @param {function} onClose  -
 * @param {$Obj} form  -
 * @param {array || null} materials  -
 * @param {object} part  -
 * @param {function} onChangeValue  -
 * @param {function} addOperations  -
 * @param {object} order  -
 * @param {boolean} is_processing_current_material  -
 *
 * @returns {} -
 */


const BatchMachiningHolesModal = ({
                                      open,
                                      onClose,
                                      form = {},
                                      materials = [],
                                      part = [],
                                      onChangeValue,
                                      addOperations,
                                      order,
                                      is_processing_current_material
                                  }) => {
    const {getT, lang} = useLang();
    const dispatch = useDispatch();
    const [typeGroup, setTypeGroup] = useState(1);

    useEffect(() => {

        return () => {
            setTypeGroup(1)
        }
    }, [open])

    return (<Modal title={getT("Групповая обработка")} open={open}
                   widthProp={window.innerWidth > 1024 ? 850 : ''}
                   onClose={() => {
                       onClose(false);
                       dispatch(resetBatchMachiningFormAC());
                       dispatch(onToggleSelectedMaterialGoodsIdOfDetailing({
                           checked_ids_part: []
                       }))
                   }}>


            <Container>
                <div style={{width: '100%'}}>
                    {is_processing_current_material &&
                        <div className="batch-machining-header" style={{display: "flex"}}>

                            <InputForm
                                type={"radio"}
                                name="radio-login"
                                cNameField={"field-radio"}
                                label={getT("Отверстия")}
                                required={"required"}
                                onChange={(event) => {
                                    setTypeGroup(1);
                                }}
                                // value={isTypeLogin.email}
                                checked={typeGroup === 1}
                            />
                            <InputForm
                                type={"radio"}
                                cNameField={"field-radio"}
                                name="radio-login"
                                label={getT("Детали")}
                                required={"required"}
                                onChange={(event) => {
                                    setTypeGroup(0);
                                }}
                                // value={isTypeLogin.phone}
                                checked={typeGroup === 0}
                            />
                            <InputForm
                                type={"radio"}
                                cNameField={"field-radio"}
                                name="radio-login"
                                label={getT("Кромки")}
                                required={"required"}
                                onChange={(event) => {
                                    setTypeGroup(2);
                                }}
                                // value={isTypeLogin.phone}
                                checked={typeGroup === 2}
                            />
                        </div>
                    }
                    {!is_processing_current_material ?
                        <GroupOperations getT={getT} key={'group-operations'} onChangeValue={onChangeValue} part={part}
                                         materials={materials} addOperations={addOperations} order={order} form={form}
                                         lang={lang}
                                         is_processing_current_material={is_processing_current_material}/>
                        :
                        typeGroup === 1 ?
                            <GroupOperations getT={getT} key={'group-operations'} onChangeValue={onChangeValue}
                                             part={part}
                                             materials={materials} addOperations={addOperations} order={order}
                                             form={form}
                                             lang={lang}
                                             is_processing_current_material={is_processing_current_material}/> :
                            typeGroup === 0 ?
                                <GroupParts getT={getT}/>
                                :
                                typeGroup === 2 ?
                                    <EdgeOperations getT={getT} material={materials?.[0]}/>

                                    : null
                    }
                </div>
            </Container>
        </Modal>

    )
}


export default BatchMachiningHolesModal;