import React, {useMemo} from 'react';
import {useBatchProcessingOfParts} from "../../../HOOK/useBatchProcessingOfParts";
import {getOrderBand} from "../../../selectors/order-selector";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import Top from "../../../Icons/Cutting/Top";
import Bottom from "../../../Icons/Cutting/Bottom";
import Left from "../../../Icons/Cutting/Left";
import Right from "../../../Icons/Cutting/Right";
import {LS_LANG} from "../../../constants";


/**
 * * @description Обновляет информацию о кромковании деталей внутри материала.
 *  * В зависимости от переданных параметров, изменения могут применяться ко всем деталям
 *  * в материале или только к выбранным.
 *
 * @param {array} parts_ids - массив, uuid деталей которые отмечены
 * @param {object} material - материал, в котором происходит групповая обработка
 * @param {object} form - значение из формы, которые нужно применить для кромкования детали
 * *  - all_parts {boolean} - Флаг, обозначающий, что изменения должны быть применены ко всем деталям материала.
 *  *  - choice_parts {boolean} - Флаг, обозначающий, что изменения должны быть применены только к выбранным деталям (из массива `parts_ids`).
 *  *  - edge {object} - Объект, содержащий данные о сторонах детали, которые нужно изменить.
 *  *      - side.db_id {null|number} - Идентификатор стороны: `null`, `0` или `band.goods_id` (уникальный идентификатор материала кромки).
 *  *      - side.isChange {boolean} - Флаг, указывающий, нужно ли применять изменения к этой стороне.
 *  */
const EdgeOperations = ({getT, material}) => {
        const lang = localStorage.getItem(LS_LANG);
        const [parts_ids, _, __, onUpdateEdgeInPartBySides] = useBatchProcessingOfParts();
        const bandsStore = useSelector(getOrderBand);
        const dispatch = useDispatch();
        const formik = useFormik({
            initialValues: {
                all_parts: 1,
                choice_parts: 0,
                edge: {
                    t: {
                        db_id: null,
                        isChange: false
                    },
                    b: {
                        db_id: null,
                        isChange: false
                    },
                    l: {
                        db_id: null,
                        isChange: false
                    },
                    r: {
                        db_id: null,
                        isChange: false
                    },
                }
            },
            onSubmit: values => {
                onUpdateEdgeInPartBySides(material, values)
            },
        });

        const bands = useMemo(() => {
            return bandsStore;
        }, [material, bandsStore]);

        const EdgeIcons = {t: Top, b: Bottom, l: Left, r: Right};

        const togglePartsSelection = (e) => {
            let fieldName = e.target.name;
            if (fieldName === 'all_parts') {
                formik.setFieldValue('all_parts', 1);
                formik.setFieldValue('choice_parts', 0);
            } else if (fieldName === 'choice_parts') {
                formik.setFieldValue('all_parts', 0);
                formik.setFieldValue('choice_parts', 1);
            }
        }
        return (
            <form onSubmit={formik.handleSubmit} className={"d-flex  flex-column"} style={{gap: "20px"}}>
                <div className="d-flex items-center flex-wrap" style={{gap: "10px"}}>
                    <div className="form-group">
                        <input type="checkbox" id="all_parts" name="all_parts" onChange={togglePartsSelection}
                               checked={!!Number(formik.values.all_parts)}
                        />
                        <label style={{marginLeft: "8px"}} htmlFor="all_parts">{getT("Все детали в материале")}</label>
                    </div>
                    <div className={`form-group ${parts_ids?.length ? " " : "disabled"}`}>
                        <input type="checkbox" id="choice_parts" name="choice_parts" onChange={togglePartsSelection}
                               checked={!!Number(formik.values.choice_parts)}

                        />
                        <label style={{marginLeft: "8px"}} htmlFor="choice_parts">{getT("Выбранные детали")}</label>
                    </div>
                </div>

                <div className={"d-flex flex-column"} style={{gap: "10px"}}>
                    {Object.keys(formik.values.edge)?.map((side) => {
                        let EdgeIcon = EdgeIcons[side];
                        let fieldSide = formik.values.edge[side];
                        return (
                            <div key={`edge_side_${side}`} className={"d-flex items-center"} style={{gap: "10px"}}>
                                <div className={"d-flex items-center"}>
                                    <input type="checkbox" checked={fieldSide.isChange} name={`edge.${side}.isChange`}
                                           onChange={formik.handleChange}/>
                                    <EdgeIcon/>
                                </div>
                                <select className={`cutting-type ${fieldSide.isChange ? " " : " disabled "}`}
                                        value={fieldSide.db_id} name={`edge.${side}.db_id`}
                                        onChange={formik.handleChange}>
                                    <option value={0} key={0}>{getT('Не кромковать')}</option>
                                    {bands.map((e) => {
                                        return <option value={e.goods_id} key={e.id}>
                                            [{e?.y}/{e?.z}] {e.hasOwnProperty('translate') && e['translate'][lang] ? e['translate'][lang] : e.name}
                                        </option>
                                    })}
                                </select>
                            </div>
                        )
                    })}

                </div>
                <div className="text-center">
                    <button className={"btn w-max-content"} type={"submit"}>{getT("Применить")}</button>
                </div>
            </form>
        );
    }
;

export default EdgeOperations;