import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getGiblabDataThunk, getGiblablDataAPIThunk} from "../../../store/reducers/CommonReducer";
import Print from "../../../Icons/Actions/Print";
import {getIsProductionOldInterface} from "../../../selectors/production-selectors";
import {updateDataCuttingForHtml} from "../../../store/reducers/CuttingChartReducer";
import {useDetectClickOutside} from "react-detect-click-outside";
import {checkOrderOperationsExist} from "../../../helpers/helpers";
import {useLang} from "../../../context/langProvider";
import {packagingPrintingHtml} from "../../../store/reducers/PackagingPrinting";
import {getOrder} from "../../../selectors/order-selector";
import {generateShortOrderDetailingHtml, generateShortOrderFullHtml} from "../../../store/thunks/cutting-thunk";
import {printHtmlReportPazThunk} from "../../../store/thunks/print-html-thunk";

const PrintMenu = () => {
    const {getT} = useLang();

    const GIBLAB_BLANK_ACTION = "blank_html";
    const GIBLAB_BLANK_SHORT_WITHOUT_ACTION = "order_blank_short_without_html";
    const dispatch = useDispatch();
    const order = useSelector(getOrder);
    const isProductionOldInterface = useSelector(getIsProductionOldInterface);

    const [print, togglePrint] = React.useState(false);
    const orderHaveOperations = checkOrderOperationsExist(order);
    const getGiblabReport = (type) => dispatch(getGiblabDataThunk(type));
    const getGiblabDataAPI = (action) => dispatch(getGiblablDataAPIThunk(action));
    const ref = useDetectClickOutside({onTriggered: () => togglePrint(false)});
    const sendPrint = () => {
        dispatch(updateDataCuttingForHtml({
            general: 0, cards: order?.cutting_card?.cards?.map(c => ({
                card_id: c.card_id, card: 1, parts_info: 0
            })), xnc: 0, mode: 1, modeDetailing: 1
        }));

    };

    const sendPrintPackaging = ()=>{
            dispatch(packagingPrintingHtml());
    }
    const handlerShortOrderFormFullHtml = ()=>{
        dispatch(generateShortOrderFullHtml(order))
    }
    const handlerShortOrderFormDetailing = ()=>{
        dispatch(generateShortOrderDetailingHtml(order))
    }

    const actionsPrint = [
        {
            name: getT("Бланк заказа"),
            isDisplay: true,
            disabled: false,
            onClick: ()=>getGiblabReport(GIBLAB_BLANK_ACTION),
            icon: <Print/>
        }, {
            name: getT("Бланк заказа (только деталировка)"),
            isDisplay: true,
            disabled: false,
            onClick: ()=>getGiblabDataAPI(GIBLAB_BLANK_SHORT_WITHOUT_ACTION),
            icon: <Print/>
        }, {
            name: getT("Спецификация (полная)"),
            isDisplay: true,
            disabled: false,
            onClick: handlerShortOrderFormFullHtml,
            icon: <Print/>
        }, {
            name: getT("Спецификация (деталировка)"),
            isDisplay: true,
            disabled: false,
            onClick: handlerShortOrderFormDetailing,
            icon: <Print/>
        }, {
            name: getT("Карты раскроя"),
            isDisplay: !!(order?.cutting_card && !Array.isArray(order?.cutting_card)),
            disabled: false,
            onClick: sendPrint,
            icon: <Print/>
        },
        {
            name: getT("Упаковка"),
            isDisplay: true,
            disabled: false,
            onClick: sendPrintPackaging,
            icon: <Print/>
        },{
            name: getT("Отчёт по пазам"),
            isDisplay: true,
            disabled: !order?.part?.length,
            onClick: ()=> dispatch(printHtmlReportPazThunk()),
            icon: <Print/>
        },
        {
            name: getT("Чертежи ЧПУ операций"),
            isDisplay: orderHaveOperations,
            disabled: false,
            onClick: ()=>  getGiblabDataAPI("ifp_xnc"),
            icon: <Print/>
        },
    ]
    return <>
        {print && <div className="shadow"></div>}
        <div ref={ref}
             className="singl-action"
             title={getT("Печать документов по заказу")}
        >
            <Print
                onClick={() => {
                    togglePrint(!print);
                }}
            />
            {print && (<div className="add prints-actions-container prints-actions-container-calculate">
                    <h3 className="add-title">{getT("Печать")}</h3>
                <div className="prints-singles-actions-container">
                    {actionsPrint
                        ?.filter(e => e.isDisplay)
                        ?.sort((a, b) => a.name.localeCompare(b.name))
                        .map((item) => {
                            if (item.hasOwnProperty('component')) {
                                return item.component;
                            }
                            return (<div
                                className={`singl-action-item  ${item.disabled ? "disabled" : ""}`}
                                onClick={item.onClick}
                            >
                                {item.icon}{" "}
                                <span className={"singl-action-item-name"}>
                                {item.name}
                            </span>
                            </div>)
                        })}
                </div>
                    {/*<div className="prints-singles-actions-container">*/}
                    {/*    <>*/}
                    {/*        <div*/}
                    {/*            className={"singl-action-item"}*/}
                    {/*            onClick={() => getGiblabReport(GIBLAB_BLANK_ACTION)}*/}
                    {/*        >*/}
                    {/*                <Print/>{" "}*/}
                    {/*                <span className={"singl-action-item-name"}>*/}
                    {/*                     {getT("Бланк заказа")}*/}
                    {/*                 </span>*/}
                    {/*        </div>*/}
                    {/*        <div*/}
                    {/*            className={"singl-action-item"}*/}
                    {/*            onClick={() => getGiblabDataAPI(GIBLAB_BLANK_SHORT_WITHOUT_ACTION)}*/}
                    {/*        >*/}
                    {/*            <Print/>{" "}*/}
                    {/*            <span className={"singl-action-item-name"}>*/}
                    {/*            {getT("Бланк заказа (только деталировка)")}*/}
                    {/*        </span>*/}
                    {/*        </div>*/}

                    {/*        {!!(order?.cutting_card && !Array.isArray(order?.cutting_card)) &&<div*/}
                    {/*            className={`singl-action-item`}*/}
                    {/*            // onClick={handlerGiblab('short_order_form_html')}*/}
                    {/*            onClick={handlerShortOrderFormFullHtml}*/}
                    {/*        >*/}
                    {/*            <Print/>{" "}*/}
                    {/*            <span className={"singl-action-item-name"}>*/}
                    {/*                 {getT("Спецификация (полная)")}*/}
                    {/*             </span>*/}
                    {/*        </div>}*/}
                    {/*        {!!(order?.part?.length) && <div*/}
                    {/*            className={`singl-action-item`}*/}
                    {/*            onClick={handlerShortOrderFormDetailing}*/}
                    {/*        >*/}
                    {/*            <Print/>{" "}*/}
                    {/*            <span className={"singl-action-item-name"}>*/}
                    {/*                 {getT("Спецификация (деталировка)")}*/}
                    {/*             </span>*/}
                    {/*        </div>}*/}
                    {/*        {!!(order?.cutting_card && !Array.isArray(order?.cutting_card)) && <div*/}
                    {/*            className={"singl-action-item"}*/}
                    {/*            onClick={() => sendPrint()}*/}
                    {/*        >*/}
                    {/*                <Print/>{" "}*/}
                    {/*                <span className={"singl-action-item-name"}>*/}
                    {/*                         {getT("Карты раскроя")}*/}
                    {/*                </span>*/}
                    {/*        </div>}*/}
                    {/*        {!!(Array.isArray(order?.packing) && !!order?.packing?.length )&&*/}
                    {/*            <div*/}
                    {/*                className={"singl-action-item"}*/}
                    {/*                onClick={() => sendPrintPackaging()}*/}
                    {/*            >*/}
                    {/*                <Print/>{" "}*/}
                    {/*                <span className={"singl-action-item-name"}>*/}
                    {/*                         {getT("Упаковка")}*/}
                    {/*                </span>*/}
                    {/*            </div>*/}
                    {/*        }*/}

                    {/*        {orderHaveOperations && <div*/}
                    {/*            className={"singl-action-item"}*/}
                    {/*            onClick={() => getGiblabDataAPI("ifp_xnc")}*/}
                    {/*        >*/}
                    {/*            <Print/>{" "}*/}
                    {/*                <span className={"singl-action-item-name"}>*/}
                    {/*                    {getT("Чертежи ЧПУ операций")}*/}
                    {/*                </span>*/}
                    {/*        </div>}*/}
                    {/*    </>*/}
                    {/*    /!*}*!/*/}




                    {/*    /!*<div*!/*/}
                    {/*    /!*  className={"singl-action-item"}*!/*/}
                    {/*    /!*  onClick={() => getGiblabDataAPI("xnc")}*!/*/}
                    {/*    /!*>*!/*/}
                    {/*    /!*  <Print />{" "}*!/*/}
                    {/*    /!*  <span className={"singl-action-item-name"}>*!/*/}
                    {/*    /!*    {getT("Чертежи ЧПУ Giblab")}*!/*/}
                    {/*    /!*  </span>*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*    /!*<GlDropdown getT={getT} order={order} key={"gl-print"} cName={"print-gl-actions"} />*!/*/}


                    {/*</div>*/}
                </div>)}
        </div>
    </>;
};

export default PrintMenu;