import React, {useState} from 'react';
import NoImage from "../../Icons/NoImage";
import DeleteRoundRed from "../../Icons/DeleteRoundRed";
import UserIcon from '../../images/icons/user.png';
import Info from "../../Icons/TableActions/Action_2";
import {useDispatch, useSelector} from "react-redux";
import {changeMaterialInfoModalValue} from "../../store/reducers/ModalReducer";
import {getAllPartSizesThunk, onChangeColorBandAC} from "../../store/reducers/OrderReducer";
import {changeAllPartEdgeThunk} from "../../store/PartThunks";
import IconArrow from "../../Icons/IconArrow";
import {SketchPicker} from "react-color";
import {useDetectClickOutside} from "react-detect-click-outside";

const ProjectMaterialsItem = ({
                                  isOrderStatusEdit,
                                  isDisabledDelete,
                                  bandSelect = false,
                                  activeBands,
                                  getT,
                                  lang,
                                  item,
                                  type,
                                  handlerDelete,
                                  bandCode,
                                  onChangePositionItem,
                                  index,
                                  typeItem,
                                  lengthArray,
                                  changeMaterialSelectChildren = false,
                                  connected_bands = null,
                                  onToggleConnectionBand = null,
                                  onToggleBandsProject = null,
                                  handlerToggleChoiceMaterialForDelete,
                                  is_choice_item
                              }) => {
    const dispatch = useDispatch();
    const showMaterialInfo = (goods_id) => {
        dispatch(changeMaterialInfoModalValue('material_id', goods_id));
        dispatch(changeMaterialInfoModalValue('isOpen', true));
    }

    const onChangeColorBand = (color) => {
        dispatch(onChangeColorBandAC({band: item, color}))
    }
    let image_resource = Array.isArray(item?.image) && item?.image?.length ? item?.image?.[0]?.small : null;
    return <div className={` project_materials_item_container ${is_choice_item && "choice"}`}
                key={`material_item_${item?.goods_id}`}>
        <div className="material-change-position-arrow">
            {lengthArray >= 1 && <>
                <IconArrow onCLickHandler={() => onChangePositionItem(typeItem, index, true)}/>
                <IconArrow top={false} onCLickHandler={() => onChangePositionItem(typeItem, index, false)}/>
            </>}

        </div>
        <div className="project_materials_item-group">
            <div className="project_materials_item_image">
                {image_resource? <img src={image_resource} alt=""/> : <NoImage/>}
            </div>
            {typeItem && typeItem === 'band' ?
                <BandColorPicker band={item} onChangeHandler={onChangeColorBand}/> : null}

        </div>

        <div className={'project_materials_item_info'}>
            <div className="project_materials_item_info_name">
                [ {item.goods_id} ] {(item.hasOwnProperty('translate') && item['translate'][lang]) ? item['translate'][lang] : item.name}
            </div>
            <div className="project_materials_item_info_type">
                {bandCode && (<><b>{bandCode}</b> | </>)} {type}
                {bandSelect && activeBands.map(active => {
                    if (active.goods_id === item.goods_id) {
                        return <ChangeBandsMaterial lang={lang} activeBands={activeBands} bandSelect={bandSelect}
                                                    currentId={item.goods_id} getT={getT}/>
                    }
                })}
                {changeMaterialSelectChildren && changeMaterialSelectChildren}

            </div>

        </div>
        <div
            className={isDisabledDelete ? "project_materials_item_delete events-none" : "project_materials_item_delete"}
            title={isDisabledDelete ? getT("Материал используется в изделии") : ""}>
            {handlerToggleChoiceMaterialForDelete && <div className="checkbox-label__input">
                <input type="checkbox"
                       id={`item_${item.id}_${item.type}`}
                       value={Number(is_choice_item)}
                       checked={is_choice_item}
                       className={"form-input-checkbox"}
                       onChange={handlerToggleChoiceMaterialForDelete}
                />
                <label className={`form-input-checkbox-label `} htmlFor={`item_${item.id}_${item.type}`}></label>
            </div>}

            {isOrderStatusEdit && <DeleteRoundRed onClick={() => handlerDelete(item.goods_id)}/>}
            {isOrderStatusEdit &&
                <Info className={'project_materials_item_info_icon'} onClick={() => showMaterialInfo(item.goods_id)}/>}
        </div>

        {item.clientMaterial && <>
            <img src={UserIcon} className={'maerials_project_client_material_icon'} alt=""/>
        </>}

        {!!(item.type === "part" && item?.connected_band?.length) &&
            <div className={'project_materials_item__connected_band'}>
                {!!(connected_bands?.length && onToggleConnectionBand) &&
                    <div className="connected_band burger-menu-wrap" onClick={onToggleConnectionBand}
                         title={getT("Кромка к материалу")}>
                        <button className="burger-btn"><i className="bars"></i><i
                            className="bars"></i><i className="bars"></i></button>
                    </div>}

                <div className="field" style={{marginLeft: "10px"}}>
                    <label className={"form-input-checkbox-label"}
                           htmlFor={`connected_only_bands${item?.id}`}
                    >
                        <input type="checkbox"
                               id={`connected_only_bands${item?.id}`}
                               className={"form-input-checkbox"}
                               checked={Boolean(item.band_only_connected)}
                               onChange={onToggleBandsProject}/>
                        <span>{getT("Кромка только в цвет")}</span>
                    </label>
                </div>
            </div>}
    </div>
};
const BandColorPicker = ({band, onChangeHandler}) => {
    const [visible, setVisible] = useState(false);
    const ref = useDetectClickOutside({onTriggered: () => setVisible(false)})
    const getBackgroundColor = (t) => {
        if (Number(t) === 0) {
            return 'white';
        }
        if (Number(t) <= 0.6) {
            // return 'rgba(104, 195, 152, 0.8)';
            return '#3500D3';
        }
        if (Number(t) > 0.6 && Number(t) <= 1.6) {
            // return 'rgba(143, 149, 199, 0.8)';
            return '#49B81F';
        }
        if (Number(t) > 1.6) {
            // return 'rgba(2, 89, 155, 0.867)';
            return '#D62D20';
        }
        return '#ffffff';
    }
    return <div className={'band-colorPicker'} ref={ref}>
        <div className="colorPicker-btn"
             style={{backgroundColor: band?.color ? band?.color : getBackgroundColor(band.z)}}
             onClick={() => setVisible(true)}
        ></div>
        {visible ?
            <div className="container-picker">
                <SketchPicker color={band?.color} onChange={(color) => {
                    onChangeHandler(color.hex)
                }
                }/>
            </div>
            : null}
    </div>


}
const ChangeBandsMaterial = ({bandSelect, activeBands, currentId, getT, lang}) => {

    const dispatch = useDispatch();
    let currentPartSize = useSelector(state => state.order.order.partSizesType);

    return (
        <> <select name="bands" style={{width: "100%"}}
                   onChange={(e) => {
                       let band_id = Number(e.target.value);
                       if (!band_id) return

                       if (currentPartSize !== 'gabarit') {
                           dispatch(getAllPartSizesThunk('gabarit'));
                       }

                       dispatch(changeAllPartEdgeThunk(band_id, currentId, () => {
                           dispatch(getAllPartSizesThunk(currentPartSize));
                       }))

                   }}
        >
            <option value={0}>{getT('Выбрать')}</option>
            {bandSelect.map(band => {
                if (band.goods_id != currentId) {
                    return <option
                        value={band.goods_id}>{(band.hasOwnProperty('translate') && band['translate'][lang]) ? band['translate'][lang] : band.name}</option>
                }
            })}
        </select>

        </>
    )
}
export default ProjectMaterialsItem;
