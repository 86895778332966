import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getRemainsDT} from "../../../selectors/cells-leftovers-selector";
import {
     onGetCellsStoragesDt,
    setRemainsDT
} from "../../../store/reducers/StorageCellsLeftovers";
import {useDetectClickOutside} from "react-detect-click-outside";
import GoBackArrow from "../../../Icons/Actions/GoBackArrow";
import Plus from "../../../Icons/Plus";
import CustomerBalancesAdd from "../CustomerBalances/CustomerBalancerAdd";
import RemainStockTable from "./RemainStockTable";
import CustomerBalancesMaterial from "../CustomerBalancesMaterial";

const Remains = ({getT, firmId}) => {
    const dispatch = useDispatch();
    const remainsDT = useSelector(getRemainsDT);


    const [pages, setPages] = useState({
        main: true,
        createInOrder: false,
        createInMaterial: false
    });
    const onTogglePageState = (key) => {
        let dt = {
            main: false,
            createInOrder: false,
            createInMaterial: false
        }
        setPages((prev) => {
            return {...dt, [key]: true}
        })
    }
    const onComeBack = () => {
        onTogglePageState('main');
    }
    const [isSelect, setIsSelect] = useState(false);
    const refSelect = useDetectClickOutside({onTriggered: () => setIsSelect(false)});

    useEffect(() => {
        dispatch(onGetCellsStoragesDt());
        dispatch(setRemainsDT());
    }, []);

    return <div className={"settings-section"}>
        {pages.main ? <div className="section-header">
                <h2 className="title">{getT('Остатки клиента')}</h2>

                <div className="select-custom select-custom__add-stocks " ref={refSelect}>
                    <span className={'select__head'} onClick={() => setIsSelect(!isSelect)}><Plus/></span>
                    {isSelect && <ul className={'select__body'}>
                        <li onClick={() => onTogglePageState('createInOrder')}>{getT('Создание  остатка  по заказу')}</li>
                        <li onClick={() => onTogglePageState('createInMaterial')}>{getT('Создание  остатка  без заказа')}</li>
                    </ul>}
                </div>
            </div>
            :
            <> <span className={'go-back'} onClick={onComeBack}><GoBackArrow/>{getT('Назад')}</span></>
        }
        {pages.main && <RemainStockTable getT={getT} remainsDT={remainsDT}/>}
        {pages.createInOrder && <CustomerBalancesAdd getT={getT} onComeBack={onComeBack}/>}
        {pages.createInMaterial && <CustomerBalancesMaterial getT={getT} onComeBack={onComeBack}/>}

    </div>
}


export default Remains;
