import {instances} from "./api_axios_settings";
import {rejectTransform, resolveTransform} from "./utils_api";
import {URI_API_EXTTUNELL} from "./uri_api_action";

/**
 * Дополнительные услуги к заказу
 * @method get  - список пользователей
 */
const advanced_service_order_get = "advanced_service_order_get"
const advanced_service_order_delete = "advanced_service_order_delete"
const advanced_service_order_set = "advanced_service_order_set"
export const AdvancedGoodsServiceAPI = {
    getAllByOrderId(orderId) {
        return instances.post(URI_API_EXTTUNELL, {id: orderId}, {
            headers: {
                action: advanced_service_order_get
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    deleteGoodsServiceById(id) {
        return instances.post(URI_API_EXTTUNELL, {id: id}, {
            headers: {
                action: advanced_service_order_delete
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    /**
     * @description {setAdvances} создать услуги к заказу
     * @param {array} api_data  - массив объектов услуг к заказу
     * пример объекта
     *          "goods_service_for_order_by_firm": "10", - текущая фирма
     *         "count": "1", - кол-во
     *         "order": "31798", - номер заказа которому применяем углугу
     *         "price": "2", - цена
     *         "currency": "5" - id валюты
     *
     * @returns {Promise<response || reject>} -
     */

    setGoodsService(api_data) {
        return instances.post(URI_API_EXTTUNELL, api_data, {
            headers: {
                action: advanced_service_order_set
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },

}