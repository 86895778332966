import React, {useCallback, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import LogoNew from "../../Icons/LogoNew";
import ShopingCard from "../../Icons/ShopingCard";
import Tabs from "./Tabs";


import "./Header.css";
import Actions from "./Actions";
import MoreVertical from "./Actions/MoreVertical";
import Settings from "./Settings/Settings";
import Save from "../../Icons/Dotts/Save";
import Upload from "../../Icons/Dotts/UploadProject";
import Download from "../../Icons/Dotts/DownloadProject";
import Gitlab from "../../Icons/Dotts/Gitlab";
import InputForm from "../InputFields/InputForm/InputForm";
import GiblabSendFormContainer from "../GiblabSendForm/GiblabSendFormContainer";
import {useDispatch, useSelector} from "react-redux";
import {
    changeAlertStatusAC,
    changeLoaderStatusAC,
    getGiblabDataThunk,
    onToggleAllowRequestCheckProject
} from "../../store/reducers/CommonReducer";
import {useDetectClickOutside} from "react-detect-click-outside";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {setSubstrateBody} from "../../helpers/helpers";
import MaterialProject from "../../Icons/MaterialProject";
import {setNotWholeRemnantsMaterialClient, setStockMaterialClient} from "../../store/reducers/ModalReducer";
import {initialSettingsFirm} from "../../store/reducers/SettingsReducer";
import Language from "../Language";
import {
    getDoNotUseResetsCompanyDuringTheOrder,
    getProductionLang,
    getProductionLogo
} from "../../selectors/production-selectors";
import ActionCuttingEdit from "../ActionCuttingEdit";
import IconCutting from "../../Icons/IconCutting";
import ApiService from "../../api/api";
import {useLang} from "../../context/langProvider";
import {getOrder, isStageMemoryOrder} from "../../selectors/order-selector";
import ImageSetting from "./ui/ImageSetting";
import PackageIframe from "../PackageIframe";
import SavedVersion from "./ui/SavedVersion";
import useProjectUdapter from "../../HOOK/useProjectUdapter";
import IconHome from "../../Icons/IconHome";
import ConfirmationOfUserData from "./ui/ConfirmationOfUserData";
import {getIsManagerSelected} from "../../selectors/auth-selector";
import {saveOrderProjectDataThunk} from "../../store/reducers/OrderReducer";
import {LS_CONNECTED_BOX3D, LS_LINK_T0_3D_CARD} from "../../constants";
import IconQuestion from "../../Icons/IconQuestion";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../store/constats/help_article_constants";
import {helpArticleGetByIdToggleModalThunk} from "../../store/thunks/help-article-thunk";

import ImgCard3D from "../../images/card3d.jpg";
import HeaderSocialWrap from "./ui/HeaderSocialWrap";

const Header = (props) => {
    const {getT} = useLang();
    const apiService = new ApiService();

    const dispatch = useDispatch();
    const order = useSelector(getOrder);
    const isManager = useSelector(getIsManagerSelected);
    const manager = useSelector((state) => state?.auth?.manager);
    const stageMemoryDtOrder = useSelector(isStageMemoryOrder)
    const productionLogo = useSelector(getProductionLogo) || '';
    const productionLan = useSelector(getProductionLang) || '';
    const productionOrderResetCompany = useSelector(getDoNotUseResetsCompanyDuringTheOrder);
    const {production_constants} = order;

    const [openVertical, setOpenVertical] = useState(false);
    const [editTitle, toggleEditTitle] = useState(false);

    const isOrderStatusEdit = useMemo(() => {
        if (!order) return false;
        return order && Number(order?.status) === 1
    }, [order]);

    const toggleVertical = (open) => {
        setSubstrateBody(open, 'vertical')
        setOpenVertical(open);
    };
    const refVertical = useDetectClickOutside({onTriggered: () => toggleVertical(false)});


    const onHandlerOpenPageSettings = useCallback(() => {
        setOpenVertical(false);
        props.onTabChange('settings');
        dispatch(initialSettingsFirm())
    }, [dispatch, props.onTabChange]);

    const {iframeCurrent, onUpdateStoreOrder, onUpdateAndGetProject} = useProjectUdapter()

    const triggerApiPackAndSaveStoreOrder = useCallback(async (project_order = null) => {
        let actual_order = project_order ? project_order : order
        try {
            dispatch(onToggleAllowRequestCheckProject(false))
            let response = await onUpdateStoreOrder(actual_order)
            dispatch(onToggleAllowRequestCheckProject(true));
            return response
        } catch (e) {
            dispatch(changeAlertStatusAC(true, e?.message));
        }

    }, [dispatch, order])

    const onGetPackedBeforeSendGiblabCalcProject = useCallback(async () => {
        dispatch(onToggleAllowRequestCheckProject(false))
        await onUpdateAndGetProject();
        sessionStorage.removeItem('calculate');
        dispatch(getGiblabDataThunk('calc'));
        dispatch(onToggleAllowRequestCheckProject(true))

    }, [dispatch]);
    const onGetPackedBeforeSendSaveProject = async (arg1 = true, arg2 = false, arg3 = false) => {
        try {
            await triggerApiPackAndSaveStoreOrder();
            props.saveProject(arg1, arg2, arg3);
        } catch (e) {
            props.saveProject(arg1, arg2, arg3);
        }

    }

    const saveProjectAndRedirect = (redirect = '/account') => async () => {
        dispatch(changeLoaderStatusAC(true));
        await triggerApiPackAndSaveStoreOrder();
        dispatch(changeLoaderStatusAC(true));
        const response_save = await dispatch(saveOrderProjectDataThunk(true, false, false));
        if (response_save) {
            window.location.href = redirect
        }
    }
    const handlerHelpArticle = (id) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(helpArticleGetByIdToggleModalThunk(id))
    }

    const handlerOpenCard3D = async (e) => {

        let redirect = apiService._baseIfurn + '/cad/?id=' + localStorage.getItem(LS_LINK_T0_3D_CARD);

        await saveProjectAndRedirect(redirect)(e)
    }


    return (
        <div className={props.activeTab === "details" ? 'header-wrapper details-header' : 'header-wrapper'}>
            <div className="logo-container">
                {isManager &&
                    <div className="company">
                        <IconHome onClick={saveProjectAndRedirect('/account')}/>
                        <ConfirmationOfUserData key={"ConfirmationOfUserData"}/>
                    </div>}

                <div className="company" ref={refVertical}>
                    {manager ? <span onClick={saveProjectAndRedirect(apiService._crmLink)}
                                     className="company-logo helper-article"> {productionLogo ?
                            <img src={productionLogo} alt=""/> :
                            <LogoNew/>}
                            <IconQuestion style={{top: '0px'}}
                                          onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.company_logo)}
                            />
                        </span> :
                        <span onClick={saveProjectAndRedirect('/account')} className="company-logo helper-article">
                            {productionLogo ? <img src={productionLogo} alt=""/> : <LogoNew/>}
                            <IconQuestion style={{top: '0px'}}
                                          onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.company_logo)}
                            />
                        </span>
                    }
                    {!!localStorage.getItem(LS_LINK_T0_3D_CARD) &&
                        <div className="card3d-wrap" onClick={handlerOpenCard3D}>
                            <img src={ImgCard3D} alt="" height={30}/>
                        </div>}
                    <div className={'company-details helper-article ' + (editTitle ? 'edit' : '')}>
                        {editTitle && (Number(order.status) === 1) ?
                            <InputForm
                                placeholder={''}
                                type="text"
                                name="title"
                                label={''}
                                onChange={(event) => props.changeTitle(event.target.value)}
                                onBlur={() => toggleEditTitle(false)}
                                value={props.title}
                            />
                            :
                            <div className={'main_service_header_save_button'}>
                                {!!props?.title ? <span className="company-details__name"
                                                        onDoubleClick={() => toggleEditTitle(true)}>{props.title}</span>
                                    :
                                    <span className="company-details__name" onDoubleClick={() => toggleEditTitle(true)}>

                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg"><path
                                            d="M9.916 1.75a1.65 1.65 0 112.334 2.333l-7.875 7.875-3.208.875.875-3.208L9.915 1.75z"
                                            stroke="#4262FF" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path></svg>
                                    </span>}

                            </div>
                        }
                        <IconQuestion style={{top: '-3px', right: '5px'}}
                                      onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.project_name)}
                        />
                    </div>
                    {isOrderStatusEdit && <>
                        <div onClick={() => {
                            onGetPackedBeforeSendSaveProject(true, false, true)
                        }} className="actions helper-article" style={{display: "flex", alignItems: 'center'}}>
                            <span className={stageMemoryDtOrder ? "stage-change__order" : ""}>
                                <Save/>
                            </span>
                            <IconQuestion style={{top: '-4px'}}
                                          onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.preservation)}
                            />
                        </div>
                        <SavedVersion handlerHelpArticle={handlerHelpArticle}/>
                    </>
                    }
                    <div onClick={() => toggleVertical(!openVertical)} className="actions helper-article">
                        <MoreVertical/>
                        <IconQuestion style={{top: '-6px'}}
                                      onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.submenu)}
                        />
                    </div>
                    {openVertical && (
                        <div className="vertical-open">
                            {isOrderStatusEdit && <>
                                <div className={'vertical-open-item helper-article'}
                                     onClick={() => onGetPackedBeforeSendSaveProject()}>
                                    <Save/>
                                    <span>{getT('Сохранить')}</span>
                                    <IconQuestion style={{top: '3px'}}
                                                  onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.sozranenie)}
                                    />
                                </div>

                            </>}
                            <div className={'vertical-open-item helper-article'}
                                 onClick={() => props.saveProjectFile()}>
                                <Upload/>
                                <span>{getT('Скачать проект')}</span>
                                <IconQuestion style={{top: '3px'}}
                                              onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.download_project)}
                                />
                            </div>

                            <div className={'vertical-open-item helper-article'}
                                 onClick={() => props.openUploadProject()}>
                                <Download/>
                                <span>{getT('Загрузить проект')}</span>
                                <IconQuestion style={{top: '3px'}}
                                              onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.upload_project)}
                                />
                            </div>
                            {isOrderStatusEdit && (Number(production_constants['production.giblab_off']) !== 1) && <>
                                <div className={'vertical-open-item helper-article'} onClick={async () => {
                                    sessionStorage.removeItem('calculate');
                                    await triggerApiPackAndSaveStoreOrder();
                                    props.sendGiblab()
                                }}>
                                    <Gitlab/>
                                    <span>{getT('Открыть в Giblab')}</span>
                                    <GiblabSendFormContainer/>
                                    <IconQuestion style={{top: '3px'}}
                                                  onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.open_giblab)}
                                    />
                                </div>
                            </>}
                            <div className="vertical-open-item helper-article"
                                 onClick={() => dispatch(setStockMaterialClient(order.client))}>
                                <MaterialProject/>
                                <span>{getT("Остатки клиента")}</span>
                                <IconQuestion style={{top: '3px'}}
                                              onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.remaining_customer_material)}
                                />
                            </div>

                            {(Number(localStorage.getItem('ifurn_service_main_user_firm')) !== 1) && (Number(productionOrderResetCompany) !== 1) &&
                                <div className="vertical-open-item helper-article"
                                     onClick={() => dispatch(setNotWholeRemnantsMaterialClient())}>
                                    <MaterialProject/>
                                    <span>{getT("Не целые остатки Компании")}</span>
                                    <IconQuestion style={{top: '3px'}}
                                                  onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.not_whole_remnants_company_material)}
                                    />
                                </div>
                            }

                            <div className="vertical-open-item mb-1"
                                 onClick={onHandlerOpenPageSettings}>
                                <ImageSetting style={{width: '20px'}}/>
                                <span>{getT('Обновить настройки фирмы')}</span>
                            </div>

                            <HeaderSocialWrap/>

                        </div>

                    )}
                </div>
                {props.activeTab === "details" ? (
                    <>
                        <div className="header-actions">
                            <Actions toggleMaterialsModal={props.toggleMaterialsModal}
                                     handlerHelpArticle={handlerHelpArticle}
                                     onUpdateAndGetProject={onUpdateAndGetProject}
                                     toggleAddPartModal={props.toggleAddPartModal}
                                     triggerApiPackAndSaveStoreOrder={triggerApiPackAndSaveStoreOrder}
                            />
                        </div>

                    </>
                ) : (<></>)}
                <div className="header-icons">

                    <div className="logo-container main-panel">
                        {props.activeTab === "details" ? (
                            <Settings check={props.check} testCheck={props.changeChecboxStatus}
                                      handlerHelpArticle={handlerHelpArticle}/>
                        ) : null}
                    </div>

                    {isOrderStatusEdit ?
                        <ActionCuttingEdit cName={'shoping-card'} icon={<IconCutting/>}>
                            <div className={`shoping-card`}>
                                <ShopingCard onClick={onGetPackedBeforeSendGiblabCalcProject}/>
                                <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.calculate_order)}
                                              style={{marginLeft: '6px'}}/>
                            </div>
                        </ActionCuttingEdit>
                        : <div className={``}></div>}

                    {manager && <div className={!manager ? "user-avatar vis" : 'user-avatar'}>
                        <Link className="link" to="/account" onClick={onGetPackedBeforeSendSaveProject}>
                            <FontAwesomeIcon icon={faUser} style={{
                                width: '25px',
                                height: '25px'
                            }}/>
                        </Link>
                    </div>
                    }

                    {manager && <div className={'header-manager-data-container'}>
                        <small>{getT('Менеджер')}:</small>
                        <Link to="/account"
                              onClick={() => onGetPackedBeforeSendSaveProject()}>
                            {manager.name}</Link>
                    </div>
                    }
                    <Language production={productionLan}/>

                </div>
            </div>

            <div className="header-tabs header-tabs-tablet">
                <Tabs getT={getT} active={props.activeTab} onChange={props.onTabChange}
                      handlerHelpArticle={handlerHelpArticle}/>
            </div>
            <div className="" style={{height: '0'}}>
                {!!Number(localStorage.getItem(LS_CONNECTED_BOX3D)) &&
                    <PackageIframe iframeCurrent={iframeCurrent} iframeLoaded={() => {
                    }}/>}
            </div>
        </div>
    );
};


export default Header;

