import reportWrapper from "./reportPaz/reportWrapper";
import header from "./reportPaz/header";
import {utilsTypeof} from "../helpers/utils-typeof";
import getT from "./getT";

export class ReportPazHtml {
    constructor() {
    }

    generateContentHtml({group_direction=null, order}) {
        return `
       <div class="container">
            ${header(order)}
            
            ${utilsTypeof.checkObject(group_direction) ? reportWrapper(group_direction) : getT("Данные отсутствуют")}
        </div>
    `
    }
}
