import {
    orderMultiChoiceMaterialAndBandDeleteInOrderAC,
    setAdditionalGoodsServicesAC,
    toggleCurrencyAdditionalGoodsServiceType
} from "../actions/order-actions";
import {dispatcherErrorThunk} from "./common-thunk";
import {changeLoaderStatusAC} from "../reducers/CommonReducer";
import ApiService from "../../api/api";
import {
    getCalculateAdditionalGoodsServiceOfPart,
    getGroupCalculateAdditionalGoodsServiceOfParts
} from "../../helpers/goods_service_order_part";
import {updateAllPartInOrderAC} from "../reducers/OrderReducer";

const serviceAPI = new ApiService()

/**
 *   доп услуги к деталям
 *   @params{number} currency - id валюты
 *   @params{boolean} is_calculate -   нужно ли пересчитать по новой валюте
 * */
export const orderSetGoodServicePartThunk = (currency, is_calculate = false) => async (dispatch, getState) => {
    try {
        let order = getState().order.order;
        dispatch(changeLoaderStatusAC(true));
        const response = await serviceAPI._getGoodServicePartOrderFirm(currency);

        if (serviceAPI._errors) {
            throw new Error(serviceAPI._getErrors())
        }
        if (!response) {
            dispatch(changeLoaderStatusAC(false));
            dispatch(setAdditionalGoodsServicesAC(null))
            return Promise.resolve(null)
        }
        if (is_calculate) {
            dispatch(toggleCurrencyAdditionalGoodsServiceType({
                goods_service_added_part_firm: getGroupCalculateAdditionalGoodsServiceOfParts(order, response),
            }))
        }
        dispatch(setAdditionalGoodsServicesAC(response));
        dispatch(changeLoaderStatusAC(false));
        return Promise.resolve(response)
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))

        return Promise.resolve(null)
    }
}

export const changeProductCountRestartCalculateGoodsServiceByPartFirm = ({
                                                                             product_id,
                                                                             product_count
                                                                         }) => (dispatch, getState) => {
    let order_state = getState().order.order;
    let part = order_state.part;
    let partSize = order_state.partSizesType;
    try {
        let updates_part = part?.map(item => {
            let has_product = Number(item.product) === Number(product_id);
            if (!has_product) return {...item}
            return {
                ...item,
                goods_service_added_firm: getCalculateAdditionalGoodsServiceOfPart(item, partSize, product_count || 1)

            }
        })
        dispatch(updateAllPartInOrderAC(updates_part))

    } catch (e) {
        console.log(e.message)
    }
}

const getFilteredActualMultiDeleteMaterialChoiceInOrder = (order, delete_material_ids) => {
    if (!delete_material_ids || !Array.isArray(delete_material_ids) || !delete_material_ids?.length) return order
    let payload = {
        ...order,
        part: order.part.filter((e) => !delete_material_ids.includes(Number(e.material))),
        material: order.material.filter((e) => !delete_material_ids.includes(Number(e.goods_id))),
        bands_type: order.bands_type.filter((e) => !delete_material_ids.includes(Number(e.band)))
    }
    return payload
}

const getFilteredActualMultiDeleteBandChoiceInOrder = (order, delete_band_ids) => {
    if (!delete_band_ids || !Array.isArray(delete_band_ids) || !delete_band_ids?.length) return order
    let payload = {
        ...order,
        bands_type: order.bands_type.filter((e) => !delete_band_ids.includes(Number(e.band))),
        band: order.band.filter((e) => !delete_band_ids.includes(Number(e.goods_id))),
        part: order.part.map((e) => {
            let edge = {...e?.edge};
            for (const side in e?.edge) {
                let db_id = edge?.[side]?.db_id;
                if (delete_band_ids.includes(Number(db_id))) {
                    edge[side] = {
                        db_id: null,
                        cut: null,
                        t: null,
                        w: null,
                        laz: null,
                        eva: null,
                        pur: null,
                        pre_joint: 1
                    }
                }
            }

            return {
                ...e, edge: edge
            };
        })
    }
    return payload;
}
export const deleteMultiChoiceMaterialAndBandForOrder = ({
                                                             material_ids = null,
                                                             band_ids = null
                                                         }) => (dispatch, getState) => {
    try {
        dispatch(changeLoaderStatusAC(true));

        let order_store = getState().order.order;
        let payload_filtered_material = getFilteredActualMultiDeleteMaterialChoiceInOrder(order_store, material_ids);
        let payload_filtered_band = getFilteredActualMultiDeleteBandChoiceInOrder(payload_filtered_material, band_ids);
        dispatch(orderMultiChoiceMaterialAndBandDeleteInOrderAC({
            part: payload_filtered_band.part,
            band: payload_filtered_band.band,
            bands_type: payload_filtered_band.bands_type,
            material: payload_filtered_band.material,
        }));
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}