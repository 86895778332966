export const FETCH_ALL_ADVANCED_GOODS_SERVICES_FOR_ORDER = 'ADVANCED_GOODS_SERVICES/FETCH_ALL_FOR_ORDER';


/**
 * @function setAdvancedGoodsServiceForOrder
 * @description Диспатчит список всех услуг для конкретного заказа по его id
 * @param {array || null} all - все услуги для конкретного idOrder
 * @param {array || null} existing - существующие услуги для конкретного idOrder
 *      all: array, // все доступные услуги
 *      existing: array // существующие услуги для заказа
 * @returns {object} Экшен для получения всех услуг для заказа
 */

export const setAdvancedGoodsServiceForOrder = ({all, existing}) => {
    return {
        type: FETCH_ALL_ADVANCED_GOODS_SERVICES_FOR_ORDER,
        payload: {all: all, existing: existing}
    }
}

/**
 * @function setOpenPageAdditionalGoodsServiceForOrder
 * @description Диспатчит состояние окна
 * @param {boolean} isOpen -
 * @returns {object} Экшен для управления состоянием модального окна
 */




