import React from 'react';
import {useBatchProcessingOfParts} from "../../../HOOK/useBatchProcessingOfParts";
import Delete from "../../../Icons/Actions/Delete";

const GroupParts = ({getT}) => {
    const [parts_ids, _, onOperationHandlerOfParts] = useBatchProcessingOfParts();
    if (!parts_ids?.length) return <p>{getT('Отсутствуют выделенные детали')}</p>
    return (
        <div className={'group-operation-parts'}>
            <div className="item" onClick={onOperationHandlerOfParts({type: 'delete'})}>
                <Delete/> {getT("Удалить выбранные детали")}
            </div>
            <div className="item" onClick={onOperationHandlerOfParts({type: 'clear-cnc'})}>
                <Delete/> {getT("Удалить ЧПУ операции")}
            </div>
            <div className="item form-field radio">

                {/*<label className={'form-input-checkbox-label normal-checkbox'}>*/}
                <div className="item">
                    <input type="radio" id={'off'} name="batch-texture" onChange={(event) => {
                        onOperationHandlerOfParts({type: 'texture', payload: false})(event)
                    }}/>
                    <label htmlFor="off">{getT('Снять')}</label>
                </div>
                <div className="item">

                    <input type="radio" id={'on'} name="batch-texture" onChange={(event) => {
                        onOperationHandlerOfParts({type: 'texture', payload: true})(event)
                    }}/>
                    <label htmlFor="on">{getT('Установить')}</label>
                </div>
                <span style={{marginLeft: '8px'}}>{getT("текстуру с выбранных деталей")}</span>
            </div>
            <div className="item" onClick={onOperationHandlerOfParts({type: 'edge'})}>
                <Delete/> {getT("Удалить кромкование с выбранных деталей")}
            </div>
        </div>
    );
};

export default GroupParts;