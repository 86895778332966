export const edgeAndProcessingLogic = {

    updateEdgeForSide: (edgeSide, band = null, preJoint = 0) => {
        return {
            ...edgeSide,
            db_id: band?.goods_id ?? null,
            w: band?.y ?? null,
            t: band?.z ?? null,
            cut: 0,
            curve: 0,
            pre_joint: preJoint,
            radius: null,
            color: band?.color || ""
        };
    }
}