import React, {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getSelectedMaterialOfDetailing} from "../selectors/order-selector";
import {onToggleSelectedMaterialGoodsIdOfDetailing} from "../store/reducers/OrderReducer";
import {batchProcessingOfParts} from "../store/PartThunks";
import {batchProcessingChangeEdgeBySideOfParts} from "../store/thunks/batch-processing-thunk";


export const useBatchProcessingOfParts = () => {
    const dispatch = useDispatch();
    const selectMaterial = useSelector(getSelectedMaterialOfDetailing);
    const parts_ids = selectMaterial.checked_ids_part;

    const onOperationHandlerOfParts= useCallback( (operation) => (event)=>{
        dispatch(batchProcessingOfParts({parts_ids: parts_ids, operation}));
    }, [parts_ids, dispatch]);

    const onUpdateEdgeInPartBySides = useCallback((material, values)=>{
        dispatch(batchProcessingChangeEdgeBySideOfParts({parts_ids, material, form: values}))

    }, [parts_ids, dispatch])

    const onToggleSetIds = useCallback((id)=>{
        const index = parts_ids.indexOf(id);
        let ids_clone = [...parts_ids];
        if (index !== -1) {
            ids_clone.splice(index, 1);
        } else {
            ids_clone.push(id);
        }
        dispatch(onToggleSelectedMaterialGoodsIdOfDetailing({checked_ids_part: ids_clone}))
    }, [dispatch, parts_ids]);

    return [parts_ids, onToggleSetIds, onOperationHandlerOfParts, onUpdateEdgeInPartBySides]
};

