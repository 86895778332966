import React from 'react';
import TelegramLogo from "../../../images/messengers/telegram_logo.png";
import ViberLogo from "../../../images/messengers/viber_logo.png";
import QrCode from "../../QrCode";
import {useLang} from "../../../context/langProvider";
import {useSelector} from "react-redux";
import {getProductionTelegramQr, getProductionViberQr} from "../../../selectors/production-selectors";

const HeaderSocialWrap = () => {
    const {getT} = useLang();
    const productionTelegramQr = useSelector(getProductionTelegramQr) || ''
    const productionViberQr = useSelector(getProductionViberQr) || ''
    const socialEntry = [
        {src: TelegramLogo, qrProps: productionTelegramQr},
        {src: ViberLogo, qrProps: productionViberQr},
    ]
    return (
        <div className="header-social-wrap">
            {socialEntry?.filter(social => social?.qrProps)?.map(social => {
                return <div className={'messengers_icons_header_container header-social-item'}>
                    <img src={social.src} alt=""/>
                    <div className="messengers_icons_header_qrs_code_container">
                        <QrCode qrProps={{value: social.qrProps, size: 80}}
                                cName={'messengers_icons_header_qrs_code'}/>

                        <small>{getT('Отсканируйте с помощью мобильного телефона')}</small>
                    </div>
                </div>
            })}
        </div>
    );
};

export default HeaderSocialWrap;