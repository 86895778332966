/**
 * checkOperationsExist. Проверить на калеченее операций
 *  @type operationTypes [Array] -  ["bore", "paz", "trough", "hem", "frez", "cut_to"]
 * @param {object} part  - current part for check
 * @returns  {boolean}  -
 */
export const checkOperationsExist = (part) => {
    const operations = part.operations || {};

    // Используем массив названий операций для упрощения логики
    const operationTypes = ["bore", "paz", "trough", "hem", "frez", "cut_to"];

    // Проходим по каждой операции и суммируем их длины
    return operationTypes.reduce((acc, type) => acc + (operations[type]?.length || 0), 0) > 0;
};

/**
 * * Определить стороны в которых нужно обновить данные по стороне кромки.
 *
 * @param {object} itemRemovedFrez -  паз, который удаляюь
 * @param {array} remainingFrez  - пазы, которые остались
 * @returns  {array || null}  - возращает массив сторон или null
 */

export const getUpdateKeyEdgeSettingsAfterFrezRemoval = (itemRemovedFrez, remainingFrez) => {
    const side = {t: 'kl_elt', b: 'kl_elb', l: 'kl_ell', r: 'kl_elr'};

    if (!itemRemovedFrez || !remainingFrez || !Array.isArray(remainingFrez) ) {
        return null; // Если входные данные некорректны
    }
    try {
        let frezMoveOne = itemRemovedFrez?.frez_move?.[0] ?? null
        if (!frezMoveOne) {
            return null
        }
        // Сбор сторон, которые есть в пазе.
        let frezItemSideForDelete = Object.keys(side).reduce((acc, keySide) => {
            let frezSide = side[keySide];
            let frezHasSide = frezMoveOne?.hasOwnProperty(frezSide) && !!Number(frezMoveOne?.[frezSide]);
            if (frezHasSide) {
                return {
                    ...acc ?? {},
                    [keySide]: frezSide,

                }
            }
            return acc
        }, null);

        if (!frezItemSideForDelete) {
            return null
        }

        let edgeResult = {};
        // Определяем стороны, которых больше нет в оставшихся пазах.
        for (let edgeSide in frezItemSideForDelete) {
            let frezSide = frezItemSideForDelete[edgeSide];
            let isRemoved = remainingFrez?.every(item=> !item?.frez_move?.[0]?.[frezSide]);
            if(isRemoved) {
                edgeResult[edgeSide] = isRemoved;
            }
        }

        return  Object.keys(edgeResult);

    } catch (e) {
        console.error('getUpdateKeyEdgeSettingsAfterFrezRemoval', e.message);
        return null;
    }
}
