import {getDetailOptionSizesOfType} from "../../helpers/helpers";
import {edgeAndProcessingLogic} from "../../helpers/part/partUpdateEdgeAndOperations";
import {updateAllPartInOrderAC} from "../reducers/OrderReducer";
import {dispatcherErrorThunk} from "./common-thunk";
import {changeLoaderStatusAC} from "../reducers/CommonReducer";


/**
 * * @description Обновляет информацию о кромковании деталей внутри материала.
 *  * В зависимости от переданных параметров, изменения могут применяться ко всем деталям
 *  * в материале или только к выбранным.
 *
 * @param {array} parts_ids - массив, uuid деталей которые отмечены
 * @param {object} material - материал, в котором происходит групповая обработка
 * @param {object} form - значение из формы, которые нужно применить для кромкования детали
 * *  - all_parts {boolean} - Флаг, обозначающий, что изменения должны быть применены ко всем деталям материала.
 *  *  - choice_parts {boolean} - Флаг, обозначающий, что изменения должны быть применены только к выбранным деталям (из массива `parts_ids`).
 *  *  - edge {object} - Объект, содержащий данные о сторонах детали, которые нужно изменить.
 *  *      - side.db_id {null|number} - Идентификатор стороны: `null`, `0` или `band.goods_id` (уникальный идентификатор материала кромки).
 *  *      - side.isChange {boolean} - Флаг, указывающий, нужно ли применять изменения к этой стороне.
 *  */

export const batchProcessingChangeEdgeBySideOfParts = ({parts_ids, material, form}) => (dispatch, getState) => {
    try {
        dispatch(changeLoaderStatusAC(true));

        let order = getState().order.order;
        const min_delta_edge_material = order?.production_constants['production.min_delta_edge_material'] || 0
        let partsOrder = order.part;
        const bandTypeOrder = order.bands_type;
        const partSizesType = order.partSizesType;
        const keysPartSize = getDetailOptionSizesOfType(partSizesType)
        const bandsOrder = order.band;


        let isChangeAllPartsOfMaterial = form.all_parts;
        let isChangePartByIdOfMaterial = form.choice_parts;
        let edgeUpdateValue = Object.keys(form.edge).reduce((acc, side) => {
            let edge = form.edge[side];
            if (edge.isChange) {
                let band_id = Number(edge.db_id)
                let db_id = !!band_id ? band_id : null;
                return {
                    ...acc, [side]: {
                        db_id: db_id
                    }
                }
            }
            return acc;
        }, {});
        let partUpdate = partsOrder.map(part => {
            let partMaterialId = Number(part.material);
            let materialId = Number(material.goods_id);
            if (partMaterialId !== materialId) {
                return part;
            }
            let isHasPartId = parts_ids.some(uuid => uuid === part.uuid);
            if (isChangeAllPartsOfMaterial || (isChangePartByIdOfMaterial && isHasPartId)) {
                // перебор значений по стороне кромки в детали
                for (let [side, value] of Object.entries(edgeUpdateValue)) {
                    let db_id = value.db_id;
                    let isChangeEdge = true;
                    let band = null;
                    let preJoint = 0;
                    if (db_id) {
                        band = bandsOrder?.find(band => Number(band.goods_id) === Number(db_id));
                        preJoint = bandTypeOrder?.find(e => Number(e.band) === Number(partMaterialId))?.pre_joint ?? 0;
                        isChangeEdge = Number(part[keysPartSize.y]) >= (Number(band.z) + Number(min_delta_edge_material));
                    }
                    if (isChangeEdge) {
                        let edgeSide = edgeAndProcessingLogic.updateEdgeForSide(part.edge[side], band, preJoint);
                        part = {
                            ...part,
                            edge: {
                                ...part.edge,
                                [side]: edgeSide
                            }
                        }

                    }
                }

                return part;

            }

            return part;

        })

        dispatch(updateAllPartInOrderAC(partUpdate));
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}