import {applyMiddleware, combineReducers, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import AuthReducer from "./reducers/AuthReducer";
import CommonReducer from "./reducers/CommonReducer";
import OrderReducer from "./reducers/OrderReducer";
import ModalReducer from "./reducers/ModalReducer";
import MaterialsReducer from "./reducers/MaterialsReducer";
import OperationsReducer from "./reducers/OperationsReducer";
import RegisterReducer from "./reducers/RegisterReducer";
import BoxesReducer from "../components/Boxes/reducer";
import {TranslationReducer} from "./reducers/TranslationReducer";
import SettingsReducer from "./reducers/SettingsReducer";
import StorageCellsLeftovers from "./reducers/StorageCellsLeftovers";
import ServiceReducer from "./reducers/ServiceReducer";
import FurnitureReducer from "./reducers/FurnitureReducer";
import CuttingChartReducer from "./reducers/CuttingChartReducer";
import PackagingPrinting from "./reducers/PackagingPrinting";
import {helpArticleReducer} from "./reducers/help-article-reducer";
import {MaterialSelectedFilterReducer} from "./reducers/MaterialSelectedFilterReducer";
import ProcessingEdgeReducer from "./reducers/ProcessingEdgeReducer";
import SearchGoodsReducer from "./reducers/SearchGoodsReducer";
import AdditionalGoodsServiceReducers from "./reducers/AdditionalGoodsServiceReducer";
function lastAction(state = null, action) {
    return action;
}

let reducers = combineReducers({
    auth: AuthReducer,
    commons: CommonReducer,
    order: OrderReducer,
    modal: ModalReducer,
    materials: MaterialsReducer,
    operations: OperationsReducer,
    register: RegisterReducer,
    boxes: BoxesReducer,
    translation: TranslationReducer,
    settingFirm: SettingsReducer,
    cellsLeftore: StorageCellsLeftovers,
    services: ServiceReducer,
    furniture: FurnitureReducer,
    cuttingChart: CuttingChartReducer,
    packagingPrinting: PackagingPrinting,
    help_article: helpArticleReducer,
    material_selected_filter: MaterialSelectedFilterReducer,
    processing_edge: ProcessingEdgeReducer,
    search_goods: SearchGoodsReducer,
    advanced_goods_service: AdditionalGoodsServiceReducers,
    lastAction
})

let store = createStore(reducers, composeWithDevTools( applyMiddleware(thunk)));

window.store = store;
store.subscribe(() => {
    // const newState = store.getState();
    // console.log(newState?.order?.order?.part)
    // console.log(store.getState()?.lastAction);
    // check out your updated state
});
export default store;
