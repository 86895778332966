import React, {useEffect, useState} from "react";
import {useDetectClickOutside} from "react-detect-click-outside";
import {useDispatch} from "react-redux";
import {getGiblabDataThunk, getGiblablDataAPIThunk} from "../../store/reducers/CommonReducer";
import Print from "../../Icons/Actions/Print";
import {checkOrderOperationsExist} from "../../helpers/helpers";
import {LABELS_CUTTING_PRINT} from "../../store/reducers/CuttingChartReducer";
import {onChangeLabelsPrintGirdModal} from "../../store/reducers/ModalReducer";

const GlDropdown = ({getT, order, cName = ''}) => {
    const [isVisible, setVisible] = useState(false);
    const orderHaveOperations = checkOrderOperationsExist(order);
    const ref = useDetectClickOutside({onTriggered: () => setVisible(false)});

    const dispatch = useDispatch();
    const getGiblabReport = (type) => dispatch(getGiblabDataThunk(type));
    const getGiblabDataAPI = (action) => dispatch(getGiblablDataAPIThunk(action));


    const onChangeValueModal = (values) => {
        dispatch(onChangeLabelsPrintGirdModal(values))

    }

    const [isKeyPress, setKeyPress] = useState(false);
    useEffect(() => {
        const onPressKey = (event) => {
            if (event.altKey && event.keyCode === 71) {
                setKeyPress(prev => !prev);
            }
        }
        window.addEventListener('keydown', onPressKey)
        return () => window.removeEventListener('keydown', onPressKey)
    }, []);
    if (!isKeyPress) return <></>

    const actionsCuttings = [
        {
            name: getT("Карты раскроя"),
            isDisplay: true,
            disabled: false,
            onClick: () => getGiblabReport("cards"),
            icon: <Print/>
        }, {
            name: getT("Полный отчет"),
            isDisplay: true,
            disabled: false,
            onClick: () => getGiblabReport("reports"),
            icon: <Print/>
        },
        {
            name: getT("Этикетки GL"),
            isDisplay: true,
            disabled: false,
            onClick: () => onChangeValueModal({
                isOpen: true,
                action: LABELS_CUTTING_PRINT.giblab
            }),
            icon: <Print/>
        },
        {
            name: getT("Чертежи ЧПУ Giblab"),
            isDisplay: !!orderHaveOperations,
            disabled: false,
            onClick: () => getGiblabDataAPI("xnc"),
            icon: <Print/>
        }
    ]

    return (
        <>
            {isVisible && <div className="shadow"></div>}

            <div ref={ref} className={`print_cutting_cards__container ${cName}`}>
                <span onClick={() => setVisible(!isVisible)} className={'gib-drop-btn'}>GL</span>
                <div className="">

                    {isVisible && <>

                        <div className="add prints-actions-container print_cutting_cards"
                             style={{padding: '10px !important'}}>
                            <div className="prints-singles-actions-container">
                                {actionsCuttings
                                    ?.filter(e => e.isDisplay)
                                    ?.sort((a, b) => a.name.localeCompare(b.name))
                                    .map((item) => {
                                        if (item.hasOwnProperty('component')) {
                                            return item.component;
                                        }
                                        return (<div
                                            className={`singl-action-item  ${item.disabled ? "disabled" : ""}`}
                                            onClick={item.onClick}
                                        >
                                            {item.icon}{" "}
                                            <span className={"singl-action-item-name"}>
                                {item.name}
                            </span>
                                        </div>)
                                    })}
                            </div>
                        </div>

                    </>}
                </div>
            </div>
        </>
    );
};

export default GlDropdown;