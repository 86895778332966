import React, {useCallback, useMemo} from "react";
import "./Total.css";
import {useDispatch, useSelector} from "react-redux";
import {changeConfirmOrderModalValue, changeShopingCardModalValue} from "../../../store/reducers/ModalReducer";
import {saveOrderProjectDataThunk} from "../../../store/reducers/OrderReducer";
import {changeCalculateDataUniversalThunk, changeCalculateDataUrgentThunk} from "../../../store/CalculateOrderThunks";
import Print from "../Print";
import {useLang} from "../../../context/langProvider";
import {getStoresDataThunk} from "../../../store/reducers/CommonReducer";
import {getOrder} from "../../../selectors/order-selector";
import {getVatAddedTaxShow, getVatAddPriceIfMoreThan} from "../../../selectors/production-selectors";
import {getCalculateAmountAndVat, getCalculateVat} from "../shoping_card_helper";

const Total = ({currencyInfo}) => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const order_state = useSelector(getOrder)
    const handlers_edge_calculate = order_state?.calculate?.handlers_edge ?? [];
    const paint_edge_calculate = order_state?.calculate?.paints_edge ?? [];
    const faska_edge_calculate = order_state?.calculate?.faska_edge ?? [];
    const srez_edge_calculate = order_state?.calculate?.srez_edge ?? [];
    const round_edge_calculate = order_state?.calculate?.round_edge ?? [];
    const goods = useSelector((state) => state.order.order.calculate.goods);
    const goods_service_added_part_firm = useSelector((state) => state.order.order.goods_service_added_part_firm);
    const goods_service_added_order_firm = useSelector((state) => state.order.order.goods_service_added_order_firm);
    const services = useSelector((state) => state.order.order.calculate.service);
    const materials = useSelector((state) => state?.order?.order?.calculate?.material?.filter(e => Number(e.count) > 0)) || [];
    const extra = useSelector((state) => state.order.order.extra);
    const urgent = useSelector((state) => state.order.order.urgent);
    const universal_calculate = useSelector((state) => state.order.order.universal_calculate);
    const productionCONST = useSelector((state) => state.order.order.production_constants);
    const production_date = useSelector((state) => state.order.order.calculate.order_ready_date);
    const production_vat = useSelector(getVatAddPriceIfMoreThan);
    const production_vat_display_short = useSelector(getVatAddedTaxShow) && !!Number(production_vat);


    const onHandlerCheckout = useCallback(async () => {

        const isUpdate = await dispatch(saveOrderProjectDataThunk());
        if (isUpdate) {
            sessionStorage.removeItem('calculate');
            await dispatch(getStoresDataThunk());
            dispatch(changeConfirmOrderModalValue('isOpen', true));
            dispatch(changeShopingCardModalValue('isOpen', false));

        } else {
            dispatch(changeShopingCardModalValue('isOpen', false));
        }
    }, [dispatch]);
    const getTotal = () => {

        const goodsTotal = goods && goods.reduce((accumulator, currentValue) => {
            let value = `${currentValue.cost}`
            return accumulator + parseFloat(value.replace(',', '.'));
        }, 0).toFixed(2);

        const servicesTotal = services && services.reduce((accumulator, currentValue) => {
            let value = `${currentValue.price}`
            return accumulator + parseFloat(value.replace(',', '.'));
        }, 0).toFixed(2);

        const materialsTotal = materials && materials.reduce((accumulator, currentValue) => {
            let value = `${currentValue.cost}`
            return accumulator + parseFloat(value.replace(',', '.'));
        }, 0).toFixed(2);

        const extraTotal = extra && extra.reduce((accumulator, currentValue) => {
            let value = `${currentValue.summ}`
            return accumulator + parseFloat(value.replace(',', '.'));
        }, 0).toFixed(2);

        const goodsPartsTotal = Array.isArray(goods_service_added_part_firm) && goods_service_added_part_firm?.length ? goods_service_added_part_firm.reduce((accumulator, currentValue) => {
            let value = `${Number(currentValue.count) * Number(currentValue.price)}`
            return accumulator + parseFloat(value.replace(',', '.'));
        }, 0).toFixed(2) : 0;
        const goodsOrderTotal = Array.isArray(goods_service_added_order_firm) && goods_service_added_order_firm?.length ? goods_service_added_order_firm.reduce((accumulator, currentValue) => {
            let value = `${Number(currentValue.count) * Number(currentValue.price)}`
            return accumulator + parseFloat(value.replace(',', '.'));
        }, 0).toFixed(2) : 0;
        return Number(Number(faska_edge_calculate?.summ ?? 0)+Number(srez_edge_calculate?.summ ?? 0)+Number(paint_edge_calculate?.summ ?? 0)+Number(round_edge_calculate?.summ ?? 0)+ Number(handlers_edge_calculate?.summ ?? 0) + Number(goodsTotal) + Number(goodsPartsTotal) + Number(goodsOrderTotal) + Number(servicesTotal) + Number(materialsTotal) + Number(extraTotal)).toFixed(2);

    };
    const total_vat = useMemo(() => {
        return getCalculateVat(getTotal(), production_vat)
    }, [getTotal, production_vat]);
    const amount_total_vat = useMemo(() => {
        return getCalculateAmountAndVat(getTotal(), total_vat)
    }, [total_vat, getTotal])
    return (
        <div className="total-wrapper">
            <div className="calculate-order-settings-container">
                <div className="calculate-order-setting-item">
                    <div className="form-field">
                        <label className={'form-input-checkbox-label'}
                               htmlFor={'calculate_order_change_urgent_checkbox'}>
                            <input type="checkbox"
                                   className="form-input form-input-checkbox"
                                   value={urgent}
                                   id={'calculate_order_change_urgent_checkbox'}
                                   onChange={() => dispatch(changeCalculateDataUrgentThunk(!urgent))}
                                   checked={urgent ? 'checked' : ''}
                            />
                            <span>{getT('Срочный заказ')}</span>
                        </label>
                    </div>
                </div>
                {/*{Number(productionCONST['production.price_m2_comb']) !== 1 && <>*/}
                <div className="calculate-order-setting-item">
                    {!Number(productionCONST['production.combine_calculate_hide_in_calc']) &&
                        <div className="form-field">
                            <label className={'form-input-checkbox-label'}
                                   htmlFor={'calculate_order_change_universal_checkbox'}>
                                <input type="checkbox"
                                       className="form-input form-input-checkbox"
                                       value={universal_calculate}
                                       id={'calculate_order_change_universal_checkbox'}
                                       onChange={() => dispatch(changeCalculateDataUniversalThunk(!universal_calculate))}
                                       checked={universal_calculate ? 'checked' : ''}
                                       disabled={Number(productionCONST['production.price_m2_comb']) === 1 ? "disabled" : ""}
                                />
                                <span>{getT('Комбинированный расчет')}</span>
                            </label>
                        </div>
                    }
                </div>
                {/*</>}*/}
            </div>
            {production_date && <>
                <div className="calculate-form-production-date-container">
                    <small>{getT('Ориентировочная дата производства:')}</small>
                    <span>{production_date}</span>
                </div>
            </>}
            <div>
                <Print order={order_state}/>
            </div>
            <div className="total-content">
                {getT("Итого за все")}:
                <span
                    className={`total content-amount ${production_vat_display_short ? 'content-amount-without-vat' : ''}`}>
                {getTotal()}
                    {!!production_vat ? production_vat_display_short ?
                        <span
                            className={"amount-total-vat"}> {currencyInfo.code} / {amount_total_vat} {currencyInfo.code} {getT("с НДС")}</span>
                        : <> + {total_vat} {getT("НДС")} = {amount_total_vat} {currencyInfo.code} </> : currencyInfo.code}
            </span>
                <button onClick={onHandlerCheckout} className="content-btn link">
                    {getT('Оформить заказ')}
                </button>
            </div>
        </div>
    );
};

export default Total;

