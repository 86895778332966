import {AdvancedGoodsServiceAPI} from "../../api/AdvancedGoodsServiceAPI";
import {dispatcherErrorThunk} from "./common-thunk";
import {changeLoaderStatusAC} from "../reducers/CommonReducer";
import {setAdvancedGoodsServiceForOrder} from "../actions/additional-goods-service-action";
import {
    toggleCurrencyAdditionalGoodsServiceForOrderType,
    toggleCurrencyAdditionalGoodsServiceType
} from "../actions/order-actions";

async function dispatchedGetAllAdvancedGoodsServiceForOrder(orderId, dispatch) {

    let response = await AdvancedGoodsServiceAPI.getAllByOrderId(orderId);
    if (!!response.error) {
        dispatch(toggleCurrencyAdditionalGoodsServiceType({
            goods_service_added_order_firm: []
        }))
        dispatch(dispatcherErrorThunk(response))
        return;
    }
    if (response?.data) {
        let existing = response?.data?.existing ?? null;
        let all = response?.data?.all ?? null;
        dispatch(setAdvancedGoodsServiceForOrder({existing: existing, all: all}));
        dispatch(toggleCurrencyAdditionalGoodsServiceForOrderType({
            goods_service_added_order_firm: existing
        }))

    }
    return response


}

export const getAllAdvancedGoodsServiceByOrderIdThunk = (orderId) => async (dispatch) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        await dispatchedGetAllAdvancedGoodsServiceForOrder(orderId, dispatch);
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        console.log(e)
        dispatch(dispatcherErrorThunk(e))
    }
}

export const deleteAdvancedGoodsServiceByIdForOrderIdThunk = (goodsId, orderId) => async (dispatch, getState) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const response = await AdvancedGoodsServiceAPI.deleteGoodsServiceById(goodsId);
        if (!!response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return;
        }
        await dispatchedGetAllAdvancedGoodsServiceForOrder(orderId, dispatch);
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        console.log(e)
        dispatch(dispatcherErrorThunk(e))
    }
}

export const setAdvancedGoodsServiceForOrderIdThunk = (form_api, orderId) => async (dispatch, getState) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const response = await AdvancedGoodsServiceAPI.setGoodsService([form_api]);
        if (!!response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return;
        }
        await dispatchedGetAllAdvancedGoodsServiceForOrder(orderId, dispatch);

        dispatch(changeLoaderStatusAC(false));
    } catch
        (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}