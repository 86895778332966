import {
    ADVANCED_GOODS_SERVICE_TOGGLE_PAGE,
    FETCH_ALL_ADVANCED_GOODS_SERVICES_FOR_ORDER
} from "../actions/additional-goods-service-action";

const INITIAL_STATE = {
    page: {
        isOpen: false
    },
    additionalGoodsServiceOfOrder: {
        existing: null,
        all: null
    },
}


const AdditionalGoodsServiceReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case FETCH_ALL_ADVANCED_GOODS_SERVICES_FOR_ORDER: {
            return {
                ...state,
                additionalGoodsServiceOfOrder: {
                    existing: action.payload.existing,
                    all: action.payload.all
                }
            }
        }

        default: {
            return state;
        }
    }
}
export default AdditionalGoodsServiceReducers;